import request from '../request'

export function fetchList(data) {
    return request({
        url: '/project-service/subConRegister/list',
        method: 'post',
        data,
    })
}

// 分包登记发起审批、重新提交
export function add(data) {
    return request({
        url: '/project-service/subConRegister/modify',
        method: 'POST',
        data
    })
}

export function changeStatus(data) {
    return request({
        url: '/project-service/subConRegister/modifyStatus',
        method: 'post',
        data,
    })
}


// 获取合同登记详情
export function fetchDetail({ id }) {
    return request({
        url: '/project-service/subConRegister/query/' + id
    })
}

// 修改分包登记，不审批
export function update(data) {
    return request({
        url: '/project-service/subConRegister/archive',
        method: 'post',
        data
    })
}