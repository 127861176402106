<template>
  <div>
    <a-table
      :data-source="list"
      bordered
      :pagination="false"
      :row-selection="{
        type: 'radio',
        hideDefaultSelections: true,
        selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
      <a-table-column title="分包名称" data-index="name" />
      <a-table-column
        title="分包方"
        data-index="companyName"
        width="240px"
        ellipsis="true"
      ></a-table-column>

      <a-table-column title="分包属性">
        <template slot-scope="text">
          <span v-if="text.isneeded === 1">设计类-必要分包</span>
          <span v-if="text.isneeded === 0">设计类-非必要分包</span>
          <span v-if="text.isneeded === 2">总承包类分包</span>
        </template>
      </a-table-column>

      <a-table-column title="分包类别">
        <template slot-scope="text">
          <DataDictFinder
            v-if="text.isneeded === 1"
            dictType="subType"
            :dictValue="text.type"
          />
          <DataDictFinder
            v-if="text.isneeded === 0"
            dictType="notNeedSubType"
            :dictValue="text.type"
          />
          <DataDictFinder
            v-if="text.isneeded === 2"
            dictType="generalSubType"
            :dictValue="text.type"
          />
        </template>
      </a-table-column>

      <a-table-column title="专业类型">
        <template slot-scope="text">
          <DataDictFinder dictType="majorTypes" :dictValue="text.major" />
        </template>
      </a-table-column>

      <a-table-column title="预计分包金额(万元)" align="right">
        <template slot-scope="text">
          <Money :money="text.planAmount" />
        </template>
      </a-table-column>

      <a-table-column title="分包状态" data-index="status" align="center">
        <template slot-scope="status">
          <div>
            <DataDictFinder
              dictType="taskReviewStatus"
              :dictValue="status"
              iconType="badge"
            />
          </div>
        </template>
      </a-table-column>

      <a-table-column
        key="control"
        title="操作"
        align="center"
        v-if="!disabled"
      >
        <template slot-scope="text, record, index">
          <a-space v-if="!text.id">
            <a href="#" @click.prevent="edit(text, index)">编辑</a>
            <a href="#" class="danger" @click.prevent="remove(index)">删除</a>
          </a-space>
        </template>
      </a-table-column>
    </a-table>

    <a-modal
      :visible="visible"
      :title="isEditing ? '编辑分包' : '新增分包'"
      :footer="null"
      @cancel="cancel"
      width="650px"
    >
      <a-form :form="form" :colon="false" @submit="handleSubmit">
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="分包名称">
              <a-input
                v-decorator="[
                  'name',
                  {
                    rules: [{ required: true, message: '请输入！' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item>
              <span class="required" slot="label">分包方</span>
              <CompanySelector
                :style="{ 'margin-top': selectedCompany.name ? '0' : '4px' }"
                @change="(company) => (selectedCompany = company)"
              >
                <a-button block style="text-align: left" class="ellipsis">
                  {{ selectedCompany.name }}
                </a-button>
              </CompanySelector>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="分包属性">
              <a-select
                v-decorator="[
                  'isneeded',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
                placeholder=""
                @change="onIsneededChange"
              >
                <a-select-option
                  v-for="item in [
                    {
                      name: '设计类-必要分包',
                      value: 1,
                    },
                    {
                      name: '设计类-非必要分包',
                      value: 0,
                    },
                    {
                      name: '总承包类分包',
                      value: 2,
                    },
                  ]"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <!-- initialValue: editingText.type, -->
            <a-form-item label="分包类别">
              <a-select
                v-decorator="[
                  'type',
                  {
                    rules: [{ required: true, message: '请选择！' }],
                  },
                ]"
                style="width: 100%"
                placeholder=""
              >
                <a-select-option
                  v-for="item in form.getFieldValue('isneeded') === 1
                    ? isNecessaryTypeList
                    : form.getFieldValue('isneeded') === 0
                    ? notNecessaryTypeList
                    : form.getFieldValue('isneeded') === 2
                    ? generalSubTypeList
                    : []"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col
            :span="12"
            v-if="
              form.getFieldValue('isneeded') === 0 &&
              form.getFieldValue('type') === 'JS3'
            "
          >
            <a-form-item label="专业类型">
              <a-select
                v-decorator="[
                  'major',
                  {
                    initialValue: editingText.major,
                    rules: [
                      {
                        required: form.getFieldValue('type') === 'JS3',
                        message: '请选择！',
                      },
                    ],
                  },
                ]"
                style="width: 100%"
                placeholder=""
              >
                <a-select-option
                  v-for="item in majorTypes"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="预计分包金额(万元)">
              <a-input-number
                :formatter="$inputNumberFormat"
                v-decorator="[
                  'planAmount',
                  {
                    rules: [{ required: true, message: '请输入！' }],
                  },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="分包状态">
              <a-select
                v-decorator="[
                  'status',
                  {
                    rules: [{ required: true, message: '请选择！' }],
                  },
                ]"
              >
                <a-select-option
                  v-for="item in [
                    {
                      name: '未就绪',
                      value: 'notReady',
                    },
                    {
                      name: '已签订',
                      value: 'signed',
                    },
                  ]"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit">保存</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CompanySelector from "@/components/company-selector";

export default {
  components: {
    CompanySelector,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
    },
    selectedRowKeys: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: this.$form.createForm(this),
      isEditing: false,
      editingText: {},
      editingIndex: -1,
      selectedCompany: {}, // 选中的公司
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    isNecessaryTypeList() {
      return this.findDataDict("subType");
    },
    notNecessaryTypeList() {
      return this.findDataDict("notNeedSubType");
    },
    generalSubTypeList() {
      return this.findDataDict("generalSubType");
    },

    // 专业类型
    majorTypes() {
      return this.findDataDict("majorTypes");
    },
    statusList() {
      return this.findDataDict("subpackageStatus");
    },
  },

  methods: {
    cancel() {
      this.$emit("setVisible", false);
      this.isEditing = false;
      this.editingText = {};
      this.editingIndex = -1;
      this.selectedCompany = {};
      this.form.resetFields();
      this.form.getFieldDecorator("type", {
        initialValue: null,
      });
    },

    onIsneededChange() {
      this.form.setFieldsValue({
        type: null,
        major: null,
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (!this.selectedCompany.id) {
            this.$message.error("请选择分包方！");
            return;
          }

          if (this.isEditing) {
            const newList = [...this.list];
            newList.splice(this.editingIndex, 1, {
              ...this.editingText,
              ...values,
              planAmount: values.planAmount * 10000,

              companyId: this.selectedCompany.id,
              companyName: this.selectedCompany.name,
            });

            this.$emit("change", newList);
            this.cancel();
          } else {
            if (this.list.find((item) => item.name === values.name)) {
              this.$message.error("分包名称重复！");
              return;
            }

            this.$emit("change", [
              ...this.list,
              {
                ...values,
                planAmount: values.planAmount * 10000,
                companyId: this.selectedCompany.id,
                companyName: this.selectedCompany.name,
              },
            ]);
            this.cancel();
          }
        }
      });
    },

    edit(text, index) {
      this.isEditing = true;
      this.$emit("setVisible", true);
      this.editingText = text;
      this.editingIndex = index;
      this.selectedCompany = {
        id: text.companyId,
        name: text.companyName,
      };
      this.form.setFieldsValue({
        name: text.name,
        isneeded: text.isneeded,
        // type: text.type,
        planAmount: text.planAmount / 10000,
        status: text.status,
      });
      this.form.getFieldDecorator("type", {
        initialValue: text.type,
      });
      // this.$forceUpdate();
    },

    remove(index) {
      const newList = [...this.list];
      newList.splice(index, 1);
      this.$emit("change", newList);
    },

    onSelectChange(keys) {
      console.log("keys", keys);
      if (keys.length > 0) {
        const obj = this.list.find((item) => item.key === keys[0]);
        console.log("obj", obj);
        if (obj) {
          if (
            obj.status === "notReady" ||
            obj.status === "ready" ||
            obj.status === "rejected"
          ) {
            this.$emit("select", keys);
          } else {
            console.log("obj", obj);
            this.$message.error("请选择未就绪、已就绪或已驳回分包！");
          }
        }
      }
    },
  },
};
</script>

